
// GET page
export const GET_PAGE_REQUEST = 'GET_PAGE_REQUEST';
export const GET_PAGE_SUCCESS = 'GET_PAGE_SUCCESS'
export const GET_PAGE_FINALLY = 'GET_PAGE_FINALLY'


// UPDATE page
export const UPDATE_PAGE_REQUEST = 'UPDATE_PAGE_REQUEST';
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS'
export const UPDATE_PAGE_FINALLY = 'UPDATE_PAGE_FINALLY'
