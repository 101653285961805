import { Modal, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import EmptyData from "../../../../components/Admin/EmptyData";
import {
  deleteCompanyCoupon,
  deleteCompanyCouponAll,
  getCompanyCoupons,
} from "../../../../store/Admin/actions/CompanyCoupon";
import {
  FaPlus,
  FaRegEdit,
  FaRegTrashAlt,
} from "react-icons/fa";
import useAppParams from "../../../../hooks/useAppParams";
import CouponsFilter from "../Filter";

const CompanyCouponsList = () => {
  const dispatch = useDispatch();
  
  const { UrlParams, handleSearch } = useAppParams();
  const { company_id } = useParams();
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { dataCoupons, loading } = useSelector((state) => state.companyCoupons);
  const params = { ...UrlParams, company_id };

  useEffect(() => {
    if (params) {
      dispatch(
        getCompanyCoupons({ params })
      );
    }
  }, []);

  useEffect(() => {
    setData(
      dataCoupons?.coupons?.map((row, index) => ({
        key: row?.id,
        id: row?.id,
        code: row?.code,
        no_of_beneficiaries: row?.no_of_beneficiaries,
        name: row?.name,
        start_date: row?.start_date,
        end_date: row?.end_date,
      }))
    );
  }, [dataCoupons]);

  const ChangePaginationCoupons = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
    // setPageAdv(number);data
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: "اسم الشركة",
      dataIndex: "name",
    },
    {
      title: "الكود",
      dataIndex: "code"
    },
    {
      title: "تاريخ بداية الصلاحية",
      dataIndex: "start_date",
    },
    {
      title: "تاريخ نهاية الصلاحية",
      dataIndex: "end_date",
    },
    {
      title: "الاجراءات",
      key: "action",
      render: (value) => (
        <Space size="middle">
          <button
            className="text-danger bg-transparent pointer"
            onClick={() => {
              confirm({ id: value.id, type: "single", action: "delete" });
            }}
          >
            <FaRegTrashAlt />
          </button>
          <Link className="text-success" to={`${value?.id}/edit`}>
            <FaRegEdit />
          </Link>
        </Space>
      ),
    },
  ];

  const confirm = ({ id, type, action }) => {
    if (action === "delete") {
      if (type === "single") {
        Modal.confirm({
          title: "هل تريد حذف الكوبون",
          okText: "تأكيد",
          cancelText: "الغاء",
          onOk: () => 
          new Promise((resolve) => {
            dispatch(
              deleteCompanyCoupon({
                id,
                callback: () => {
                  dispatch(
                    getCompanyCoupons({ params })
                  );
                  resolve();
                },
              })
            );
          }),
        });
      } else if (type === "multible") {
        if (selectedRows?.length === 0 || selectedRows?.length === undefined) {
          Modal.confirm({
            title: "الرجاء تحديد كوبون",
            okText: "موافق",
            cancelText: "الغاء",
          });
        } else {
          Modal.confirm({
            title: "هل تريد حذف الكوبون",
            okText: "تأكيد",
            cancelText: "الغاء",
            onOk: () => {
              dispatch(
                deleteCompanyCouponAll({
                  selectedRows,
                  callback: () => {
                    setSelectedRows([]);
                    dispatch(
                      getCompanyCoupons({ params })
                    );
                  },
                })
              );
            },
          });
        }
      }
    }
  };

  return (
    <>

      <CouponsFilter noneField={false} />
    
      <div className="mb-15 group-btn">
        <Link className="btn btn-add" to="create">
          <span className="icon">
            <FaPlus />
          </span>{" "}
          اضافة
        </Link>
        <button
          className="btn btn-delete"
          onClick={() => {
            confirm({ type: "multible", action: "delete" });
          }}
        >
          {" "}
          <span className="icon">
            <FaRegTrashAlt />
          </span>{" "}
          حذف
        </button>
      </div>
      <Table
        locale={{ emptyText: <EmptyData /> }}
        size="small"
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          current: Number(params?.page ? params?.page : 1),
          total: dataCoupons?.total || 0,
          onChange: (page) => {
            ChangePaginationCoupons(page);
          },
        }}
      />
    </>
  );
};

export default CompanyCouponsList;
