import React, { useEffect, useState } from 'react';
import { Button, Row, Form, Col } from "antd";
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AgoraRTCProvider, useRTCClient } from "agora-rtc-react";
import { agoraAppId } from '../../../environment';
import Videos from './videos/videos';
import ChatMenu from './chat/chat';
import SessionErrorComponent from "./error/error";
import {
  finishSession,
  getSessionDetails,
  joinSession,
  leaveSession,
} from "../../../store/Website/actions/session-meeting";
import Loading from '../../../components/Admin/Loading/Loading';
import { verifyAuthToken } from '../../../store/Website/actions/home';

const SessionMeeting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id, user_token } = useParams();
  const client = useRTCClient(AgoraRTC.createClient({ codec: "vp8", mode: "rtc" }));
  const [channelName, setChannelName] = useState();
  const [AppID] = useState(agoraAppId);
  const [agora_token, setToken] = useState();
  const [inCall, setInCall] = useState(false);
  const { joinSessionData, loading: loadingJoin } = useSelector((state) => state.joinSession);
  const { getSessionData } = useSelector((state) => state.getSession);
  const { verifyAuthData } = useSelector((state) => state.verifyAuthToken);
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [users, setUsers] = useState([]);
  const [localTracks, setLocalTracks] = useState([]);

  const handleUserJoined = async (user, mediaType) => {
    await client.subscribe(user, mediaType);

    if (mediaType === 'video') {
      setUsers((previousUsers) => {
        const usersArray = [...previousUsers];
        const foundUser = usersArray.filter((userItem) => userItem.id == user.id);
        if (!foundUser) usersArray.push(user);
        return usersArray;
      });
    }

    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  };

  const handleUserLeft = (user) => {
    setUsers((previousUsers) =>
      previousUsers.filter((u) => u.uid !== user.uid)
    );
  };

  useEffect(() => {
    try {
      // replace admin token by that of user who requested to join session
      dispatch(verifyAuthToken(user_token));
      // get session details data
      dispatch(getSessionDetails({ id }));
    } catch (error) {
      setIsError(true);
    }
  }, []);

  const joinChannel = () => {
    dispatch(joinSession({ id }));

    if (joinSessionData) {
      setChannelName(joinSessionData.channalName);
      setToken(joinSessionData.token);
      setInCall(true);

      client.on('user-published', handleUserJoined);
      client.on('user-left', handleUserLeft);
  
      client
        .join(AppID, joinSessionData.channalName, joinSessionData.token, 0)
        .then((uid) =>
          Promise.all([
            AgoraRTC.createMicrophoneAndCameraTracks(),
            uid,
          ])
        )
        .then(([tracks, uid]) => {
          const [audioTrack, videoTrack] = tracks;
          setLocalTracks(tracks);
          setUsers((previousUsers) => [
            ...previousUsers,
            {
              uid,
              id: joinSessionData.user.id,
              videoTrack,
              audioTrack,
            },
          ]);
          client.publish(tracks);
        });
  
      return () => {
        for (let localTrack of localTracks) {
          localTrack.stop();
          localTrack.close();
        }
        client.off('user-published', handleUserJoined);
        client.off('user-left', handleUserLeft);
        client.unpublish(localTracks).then(() => client.leave());
      };
    }
  };

  const leaveChannel = async () => {
    dispatch(
      leaveSession({
        id,
        callback: () => {},
      })
    );

    await client.leave();
    setInCall(false);
    navigate('/');
  };

  const finishChannel = async () => {
    dispatch(
      finishSession({
        id,
        callback: () => {},
      })
    );

    await client.leave();
    setInCall(false);
    navigate('/');
  };

  return (
    <Row>
      {
        !inCall ?
          (<></>) :
          (
            <Col span={0} lg={25} style={{ display: 'flex', justifyContent: 'start', margin: '25px 25px' }}>
              <ChatMenu
                currentUserId={joinSessionData.user.id}
                conversationid={getSessionData.conversationid}
              ></ChatMenu>
            </Col>
          )
      }
      <Col span={24} lg={ !inCall ? 24 : 15 } style={{ margin: !inCall ? '135px 25px' : '0px 25px' }}>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'inline-flex' }}>
            {users.map((user) => (
              <AgoraRTCProvider client={client}>
                  <Videos
                      key={user.uid}
                      user={user}
                      channelName={channelName}
                      AppID={AppID}
                      token={agora_token}
                    />
              </AgoraRTCProvider>
            ))}
          </div>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'center', marginRight: '100px' }}>
          {
            !isError ?
              (
                !getSessionData ?
                  (<Loading />) :
                  (
                    !inCall ?
                      (
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingJoin}
                            onClick={() => joinChannel()}
                            style={{ marginTop: '50px' }}
                          >
                            {t('Join Session')}
                          </Button>
                        </Form.Item>
                      ) :
                      (
                        <Form.Item style={{ width: '400px' }}>
                          <Form.Item
                            style={{ display: 'inline-block', width: 'calc(40% - 6px)', margin: '50px 6px' }}
                          >
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={() => leaveChannel()}
                              style={{ marginTop: '50px' }}
                            >
                              {t('Leave Session')}
                            </Button>
                          </Form.Item>
                          <Form.Item
                            style={{ display: 'inline-block', width: 'calc(40% - 6px)', margin: '50px 6px' }}
                          >
                            <Button
                              type="dashed"
                              htmlType="submit"
                              onClick={() => finishChannel()}
                              style={{ marginTop: '50px' }}
                            >
                              {t('Finish Session')}
                            </Button>
                          </Form.Item>
                        </Form.Item>
                      )
                  )
              )
            : (<SessionErrorComponent></SessionErrorComponent>)
          }
        </Row>
      </Col>
    </Row>
  );
};

export default SessionMeeting;
