import { Modal, notification } from "antd";
import axiosApp from "../../../../api/index.js";
import {
  CREATE_COMPANY_FINALLY,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  GET_COMPANY_FINALLY,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANYS_REQUEST,
  GET_COMPANYS_SUCCESS,
  GET_COMPANYS_FINALLY,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FINALLY,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FINALLY,
} from "./types.js";

export const getCompany = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPANY_REQUEST,
    });
    const response = await axiosApp.get(`/admin/companies/${id}`);
    dispatch({
      type: GET_COMPANY_SUCCESS,
      payload: response.data.data,
    });
  }  catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_COMPANY_FINALLY,
    });
  }
};

export const createCompany = ({ values, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_COMPANY_REQUEST,
    });
    const response = await axiosApp.post(
      `/admin/companies`,
      values,
      { headers: { "Content-Type": "application/json" } },
    );
    dispatch({
      type: CREATE_COMPANY_SUCCESS,
      payload: response.data.data,
    });
    callback()
    notification.success({
      message: 'تمت اضافة الشركة بنجاح',
      duration: 2,
    })
  }  catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error?.response?.data?.message || "حدث خطأ ما!",
    });
  } finally {
    dispatch({
      type: CREATE_COMPANY_FINALLY,
    });
  }
};

export const getCompanies = ({ params }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPANYS_REQUEST,
    });
    const response = await axiosApp.get(`/admin/companies` , { params });
    dispatch({
      type: GET_COMPANYS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_COMPANYS_FINALLY,
    });
  }
};

export const updateCompany = ({ id, values, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_COMPANY_REQUEST,
    });
    const response = await axiosApp.patch(
      `/admin/companies/${id}`,
      values,
      { headers: { "Content-Type": "application/form-data" } },
    );
    dispatch({
      type: UPDATE_COMPANY_SUCCESS,
      payload: response.data.data,
    });
    callback()
    notification.success({
      message: "تم تعديل الشركة بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error?.response?.data?.message || error?.response?.data?.body?.message || "حدث خطأ ما!",
    });
  } finally {
    dispatch({
      type: UPDATE_COMPANY_FINALLY,
    });
  }
};

export const deleteCompany = ({ id, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COMPANY_REQUEST,
    });
    const response = await axiosApp.delete(
      `/admin/companies/${id}`,
      { headers: { "Content-Type": "application/json" }, },
    );
    dispatch({
      type: DELETE_COMPANY_SUCCESS,
      payload: response.data.data,
    });
    callback();
    notification.success({
      message: "تم حذف الشركة بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  }  catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error?.response?.data?.message || "حدث خطأ ما!",
    });
  } finally {
    dispatch({ type: DELETE_COMPANY_FINALLY });
  }
};


export const deleteCompanyAll = ({ selectedRows, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COMPANY_REQUEST,
    });
    const response = await axiosApp.delete(
      `/admin/companies/${selectedRows.toString()}`,
      { headers: { "Content-Type": "application/json" } },
    );
    dispatch({
      type: DELETE_COMPANY_SUCCESS,
      payload: response.data.data,
    });
    callback();
    notification.success({
      message: "تم حذف الشركة بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  }catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: DELETE_COMPANY_FINALLY,
    });
  }
};

