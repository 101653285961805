import { useTranslation } from "react-i18next";
import Loading from "../../Admin/Loading/Loading";
import { Card } from "antd";

const Convention = ({ loading, item, intro }) => {
    const { t } = useTranslation();

    return (
        !loading ? (
            <div className='container' style={{justifyContent: 'center', marginTop: '200px'}}>
                <Card style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    transition: 'box-shadow 0.3s ease',
                    margin: '120px',
                }} >
                    <div style={{ padding: '20px', margin: '0 auto' }}>
                        <h1 className='m-1' style={{ fontWeight: 'bolder', textAlign: 'center', fontSize: 30 }}>{item.title}</h1>
                        <p className='m-5' style={{ fontWeight: 'bold', fontSize: 20 }}>
                            {t(intro)}
                        </p>
                        <p className='m-4' style={{ fontSize: 18 }}>
                            {item.data}
                        </p>
                    </div>
                </Card>
            </div>
        ) : (<Loading />)
    );
};

export default Convention;
