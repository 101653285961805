import { useTranslation } from "react-i18next";

const SessionErrorComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="container" style={{ height: '285px' }}>
                <div className="flex justify-center mt-40">
                    <p className="text-xl font-bold text-center text-dark">{t('Session error message')}</p>
                </div>
            </div>
        </>
    );
};

export default SessionErrorComponent;
