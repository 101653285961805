import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPolicy } from '../../../store/Website/actions/constants';
import Convention from '../../../components/Website/Convention/convention';

const PrivacyPolicy = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { dataPolicy, loading } = useSelector((state) => state.policy);
    useEffect(() => {
        dispatch(getPolicy());
    }, [dispatch]);

    const policy = dataPolicy?.data || {};

    return (<Convention item={policy} intro={'policy_intro'} loading={loading}></Convention>);
};

export default PrivacyPolicy;
