
// COMMENTS
export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST';
export const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS'
export const GET_COMMENTS_FINALLY = 'GET_COMMENTS_FINALLY'


// DELETE
export const DELETE_COMMENTS_REQUEST = 'DELETE_COMMENTS_REQUEST';
export const DELETE_COMMENTS_SUCCESS = 'DELETE_COMMENTS_SUCCESS'
export const DELETE_COMMENTS_FINALLY = 'DELETE_COMMENTS_FINALLY'


// SHOW
export const SHOW_COMMENTS_REQUEST = 'SHOW_COMMENTS_REQUEST';
export const SHOW_COMMENTS_SUCCESS = 'SHOW_COMMENTS_SUCCESS'
export const SHOW_COMMENTS_FINALLY = 'SHOW_COMMENTS_FINALLY'

