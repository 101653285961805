import {
  GET_REPORT_FINALLY,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORTS_FINALLY,
  GET_REPORTS_REQUEST,
  GET_REPORTS_SUCCESS,
  GET_SESSIONS_FINALLY,
  GET_SESSIONS_REQUEST,
  GET_SESSIONS_SUCCESS,
} from "../../actions/sessions/types";

export const sessions = function (state = {}, action) {
  switch (action.type) {
    case GET_SESSIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataSessions: action.payload,
      };
    case GET_SESSIONS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const reports = function (state = {}, action) {
  switch (action.type) {
    case GET_REPORTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataReports: action.payload,
      };
    case GET_REPORTS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const report = function (state = {}, action) {
  switch (action.type) {
    case GET_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        dataReport: action.payload,
      };
    case GET_REPORT_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
