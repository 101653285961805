import React, { useEffect } from 'react';
import { Layout, Menu, List, Avatar, Form, Input, Row, Button } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import user from "../../../../assets/images/user.png";
import { openMesssageSingle, sendNewMessage } from '../../../../socketConnection';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const { Sider } = Layout;

const ChatMenu = (props) => {
  const { t } = useTranslation();
  const { dataMessagesSingle } = useSelector((state) => state.messagesSingle);
  const { conversationid, currentUserId } = props;

  const [form] = Form.useForm();
  const onFinish = (values) => {
    sendNewMessage({
      text: values.text,
      id: conversationid,
    })
    form.resetFields();
  };

  useEffect(() => {
    openMesssageSingle(conversationid);
  }, [conversationid]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={300} className="site-layout-background" style={{ backgroundColor: 'aliceblue' }}>
        <Menu
          mode="inline"
          defaultSelectedKeys={['1']}
          style={{ height: '15%', borderRight: 0 }}
        >
          <Menu.Item key="1" icon={<MessageOutlined />}>
          {t('Messages')}
          </Menu.Item>
        </Menu>
        <Form
          form={form}
          onFinish={onFinish}
        >
          <Row>
            <Form.Item
              name="text"
              rules={[
                { required: true },
              ]}
            >
              <Input placeholder="اكتب رسالة ..." />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
            >
                {t('Send')}
            </Button>
          </Row>
        </Form>
        <List
          itemLayout="horizontal"
          dataSource={dataMessagesSingle?.messages || [{ id: 1, text: 'مرحبا', senderid: 1472 }, { id: 2, text: 'أهلا بك', senderid: 1473 }]}
          renderItem={item => (
            <List.Item style={{
              backgroundColor: item.senderid != currentUserId ? 'whitesmoke' : 'slateblue',
              borderRadius: '10px'
            }}>
              <List.Item.Meta
                key={item.id}
                avatar={<Avatar src={dataMessagesSingle?.avatar || user} />}
                title={dataMessagesSingle?.full_name_ar || dataMessagesSingle?.full_name || 'أحمد محمد محمود'}
                description={item?.text}
              />
            </List.Item>
          )}
          style={{ marginTop: 20 }}
        />
      </Sider>
    </Layout>
  );
};

export default ChatMenu;
