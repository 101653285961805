// COMPANYCOUPON
export const GET_COMPANYCOUPON_REQUEST = 'GET_COMPANYCOUPON_REQUEST';
export const GET_COMPANYCOUPON_SUCCESS = 'GET_COMPANYCOUPON_SUCCESS'
export const GET_COMPANYCOUPON_FINALLY = 'GET_COMPANYCOUPON_FINALLY'

export const CREATE_COMPANYCOUPON_REQUEST = 'CREATE_COMPANYCOUPON_REQUEST';
export const CREATE_COMPANYCOUPON_SUCCESS = 'CREATE_COMPANYCOUPON_SUCCESS'
export const CREATE_COMPANYCOUPON_FINALLY = 'CREATE_COMPANYCOUPON_FINALLY'

export const GET_COMPANYCOUPONS_REQUEST = 'GET_COMPANYCOUPONS_REQUEST';
export const GET_COMPANYCOUPONS_SUCCESS = 'GET_COMPANYCOUPONS_SUCCESS'
export const GET_COMPANYCOUPONS_FINALLY = 'GET_COMPANYCOUPONS_FINALLY'

export const UPDATE_COMPANYCOUPON_REQUEST = 'UPDATE_COMPANYCOUPON_REQUEST';
export const UPDATE_COMPANYCOUPON_SUCCESS = 'UPDATE_COMPANYCOUPON_SUCCESS'
export const UPDATE_COMPANYCOUPON_FINALLY = 'UPDATE_COMPANYCOUPON_FINALLY'

export const DELETE_COMPANYCOUPON_REQUEST = 'DELETE_COMPANYCOUPON_REQUEST';
export const DELETE_COMPANYCOUPON_SUCCESS = 'DELETE_COMPANYCOUPON_SUCCESS'
export const DELETE_COMPANYCOUPON_FINALLY = 'DELETE_COMPANYCOUPON_FINALLY'

export const GET_COMPANYCOUPONS_REQUEST_SELECTION = 'GET_COMPANYCOUPONS_REQUEST_SELECTION';
export const GET_COMPANYCOUPONS_SUCCESS_SELECTION = 'GET_COMPANYCOUPONS_SUCCESS_SELECTION'
export const GET_COMPANYCOUPONS_FINALLY_SELECTION = 'GET_COMPANYCOUPONS_FINALLY_SELECTION'
