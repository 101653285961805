// DISCOUNTCOUPON
export const GET_DISCOUNTCOUPON_REQUEST = 'GET_DISCOUNTCOUPON_REQUEST';
export const GET_DISCOUNTCOUPON_SUCCESS = 'GET_DISCOUNTCOUPON_SUCCESS'
export const GET_DISCOUNTCOUPON_FINALLY = 'GET_DISCOUNTCOUPON_FINALLY'

export const CREATE_DISCOUNTCOUPON_REQUEST = 'CREATE_DISCOUNTCOUPON_REQUEST';
export const CREATE_DISCOUNTCOUPON_SUCCESS = 'CREATE_DISCOUNTCOUPON_SUCCESS'
export const CREATE_DISCOUNTCOUPON_FINALLY = 'CREATE_DISCOUNTCOUPON_FINALLY'

export const GET_DISCOUNTCOUPONS_REQUEST = 'GET_DISCOUNTCOUPONS_REQUEST';
export const GET_DISCOUNTCOUPONS_SUCCESS = 'GET_DISCOUNTCOUPONS_SUCCESS'
export const GET_DISCOUNTCOUPONS_FINALLY = 'GET_DISCOUNTCOUPONS_FINALLY'

export const UPDATE_DISCOUNTCOUPON_REQUEST = 'UPDATE_DISCOUNTCOUPON_REQUEST';
export const UPDATE_DISCOUNTCOUPON_SUCCESS = 'UPDATE_DISCOUNTCOUPON_SUCCESS'
export const UPDATE_DISCOUNTCOUPON_FINALLY = 'UPDATE_DISCOUNTCOUPON_FINALLY'

export const DELETE_DISCOUNTCOUPON_REQUEST = 'DELETE_DISCOUNTCOUPON_REQUEST';
export const DELETE_DISCOUNTCOUPON_SUCCESS = 'DELETE_DISCOUNTCOUPON_SUCCESS'
export const DELETE_DISCOUNTCOUPON_FINALLY = 'DELETE_DISCOUNTCOUPON_FINALLY'
