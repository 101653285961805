// LECTURES
export const GET_ADVS_REQUEST = 'GET_ADVS_REQUEST';
export const GET_ADVS_SUCCESS = 'GET_ADVS_SUCCESS'
export const GET_ADVS_FINALLY = 'GET_ADVS_FINALLY'

export const GET_SESSIONS_FOR_ADV_REQUEST = 'GET_SESSIONS_FOR_ADV_REQUEST';
export const GET_SESSIONS_FOR_ADV_SUCCESS = 'GET_SESSIONS_FOR_ADV_SUCCESS'
export const GET_SESSIONS_FOR_ADV_FINALLY = 'GET_SESSIONS_FOR_ADV_FINALLY'

export const GET_ONE_ADV_REQUEST = 'GET_ONE_ADV_REQUEST';
export const GET_ONE_ADV_SUCCESS = 'GET_ONE_ADV_SUCCESS'
export const GET_ONE_ADV_FINALLY = 'GET_ONE_ADV_FINALLY'

export const UPDATE_ADV_REQUEST = 'UPDATE_ADV_REQUEST';
export const UPDATE_ADV_SUCCESS = 'UPDATE_ADV_SUCCESS'
export const UPDATE_ADV_FINALLY = 'UPDATE_ADV_FINALLY'

export const CREATE_ADVS_REQUEST = 'CREATE_ADVS_REQUEST';
export const CREATE_ADVS_SUCCESS = 'CREATE_ADVS_SUCCESS'
export const CREATE_ADVS_FINALLY = 'CREATE_ADVS_FINALLY'

export const DELETE_ADV_REQUEST = 'DELETE_ADVS_REQUEST';
export const DELETE_ADV_SUCCESS = 'DELETE_ADVS_SUCCESS'
export const DELETE_ADV_FINALLY = 'DELETE_ADV_FINALLY'
