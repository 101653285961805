
// CANCELLAIONFEE
export const GET_CANCELLAIONFEE_REQUEST = 'GET_CANCELLAIONFEE_REQUEST';
export const GET_CANCELLAIONFEE_SUCCESS = 'GET_CANCELLAIONFEE_SUCCESS'
export const GET_CANCELLAIONFEE_FINALLY = 'GET_CANCELLAIONFEE_FINALLY'

// CANCELLAIONFEEPOST
export const GET_CANCELLAIONFEEPOST_REQUEST = 'GET_CANCELLAIONFEEPOST_REQUEST';
export const GET_CANCELLAIONFEEPOST_SUCCESS = 'GET_CANCELLAIONFEEPOST_SUCCESS'
export const GET_CANCELLAIONFEEPOST_FINALLY = 'GET_CANCELLAIONFEEPOST_FINALLY'

// CANCELLAIONFEEPOST
export const GET_CANCELLAIONFEEDELETE_REQUEST = 'GET_CANCELLAIONFEEDELETE_REQUEST';
export const GET_CANCELLAIONFEEDELETE_SUCCESS = 'GET_CANCELLAIONFEEDELETE_SUCCESS'
export const GET_CANCELLAIONFEEDELETE_FINALLY = 'GET_CANCELLAIONFEEDELETE_FINALLY'
