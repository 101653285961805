import { Card, Select, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getFieldsVisitsChart } from "../../../../store/Admin/actions/dashboard";
import { useDispatch, useSelector } from "react-redux";
const Option = Select.Option;

const FieldsVisitsChart = () => {
  const dispatch = useDispatch();
  const [fieldsVisitsChart, setFieldsVisitsChart] = useState([]);
  const { dataFieldsVisitsChart, loading } = useSelector(
    (state) => state.fieldsVisitsChart
  );
  const [interval, setInterval] = useState([]);
  useEffect(() => {
    dispatch(
      getFieldsVisitsChart({ params: { interval } })
    );
  }, [dispatch, interval]);

  useEffect(() => {
    setFieldsVisitsChart(
      dataFieldsVisitsChart?.data?.map((row) => ({
        name: row.name,
        count: row.count,
      }))
    );
  }, [dataFieldsVisitsChart]);

  var series2 = [
    {
      name: "الزيارات علي المجالات",
      data: fieldsVisitsChart?.map((item) => {
        return item?.count;
      }),
    },
  ];
  var options2 = {
    chart: {
      height: 280,
      type: "bar",
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#64549C",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
    xaxis: {
      categories: fieldsVisitsChart?.map((item) => {
        return item?.name;
      }),
      labels: {
        offsetX: -15,
        offsetY: 50,
      },
    },
  };
  const handleChangeSession = (values) => {
    setInterval(values);
  };
  return (
    <Card className="mb-10">
      <div className="d-flex align-items-center justify-content-between mb-30">
        <h5>إحصائية الزيارات علي المجالات من التطبيق </h5>
        <Select
          defaultValue="4"
          onChange={handleChangeSession}
          style={{ width: 120 }}
        >
          <Option value="4">يومي</Option>
          <Option value="3">اسبوعي</Option>
          <Option value="2">شهري</Option>
          <Option value="1">سنوي</Option>
        </Select>
      </div>
      {loading ? (
        <Skeleton />
      ) : (
        <ReactApexChart
          options={options2}
          series={series2}
          height="250"
          type="bar"
        />
      )}
    </Card>
  );
};

export default FieldsVisitsChart;
