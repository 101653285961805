
// USERS
export const GET_USERS_ACTIVATION_REQUEST = 'GET_USERS_ACTIVATION_REQUEST';
export const GET_USERS_ACTIVATION_SUCCESS = 'GET_USERS_ACTIVATION_SUCCESS'
export const GET_USERS_ACTIVATION_FINALLY = 'GET_USERS_ACTIVATION_FINALLY'




export const DELETE_USER_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USER_ERROR = 'DELETE_USERS_ERROR'


export const ACTIVIATE_USER_REQUEST = 'ACTIVIATE_USERS_REQUEST';
export const ACTIVIATE_USER_SUCCESS = 'ACTIVIATE_USERS_SUCCESS'
export const ACTIVIATE_USER_ERROR = 'ACTIVIATE_USERS_ERROR'


export const ACTIVIATE_ONE_USER_REQUEST = 'ACTIVIATE_ONE_USER_REQUEST';
export const ACTIVIATE_ONE_USER_SUCCESS = 'ACTIVIATE_ONE_USERS_SUCCESS'
export const ACTIVIATE_ONE_USER_ERROR = 'ACTIVIATE_ONE_USERS_ERROR'
