

import { Modal, notification } from "antd";
import axiosApp from "../../../../api/index.js";
import {
  CREATE_ADVS_FINALLY,
  CREATE_ADVS_REQUEST,
  CREATE_ADVS_SUCCESS,
  DELETE_ADV_FINALLY,
  DELETE_ADV_REQUEST,
  DELETE_ADV_SUCCESS,
  GET_SESSIONS_FOR_ADV_FINALLY,
  GET_SESSIONS_FOR_ADV_REQUEST,
  GET_SESSIONS_FOR_ADV_SUCCESS,
  GET_ONE_ADV_FINALLY,
  GET_ONE_ADV_REQUEST,
  GET_ONE_ADV_SUCCESS,
  GET_ADVS_FINALLY,
  GET_ADVS_REQUEST,
  GET_ADVS_SUCCESS,
  UPDATE_ADV_FINALLY,
  UPDATE_ADV_REQUEST,
  UPDATE_ADV_SUCCESS,
} from "./types.js";

export const getSessionsList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SESSIONS_FOR_ADV_REQUEST,
    });
    const response = await axiosApp.get(`/admin/sessions/list/create`);
    dispatch({
      type: GET_SESSIONS_FOR_ADV_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_SESSIONS_FOR_ADV_FINALLY,
    });
  }
};

export const geAdvertisements = ({ params }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADVS_REQUEST,
    });
    const response = await axiosApp.get(`/admin/advertisements` , { params });
    dispatch({
      type: GET_ADVS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_ADVS_FINALLY,
    });
  }
};

export const getOneAdvertisement = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ONE_ADV_REQUEST,
    });
    const response = await axiosApp.get(`/admin/advertisements/${id}`);
    dispatch({
      type: GET_ONE_ADV_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_ONE_ADV_FINALLY,
    });
  }
};

export const createAdvertisement = ({ values , callback }) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_ADVS_REQUEST,
    });
    const response = await axiosApp.post(
      `/admin/advertisements/`,
      values,
      { headers: { "Content-Type": "application/form-data" } },
    );
    dispatch({
      type: CREATE_ADVS_SUCCESS,
      payload: response.data.data,
    });
    callback()
    notification.success({
      message: "تم اضافة الاعلان بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message || error.response.data.body.message,
    });
  } finally {
    dispatch({
      type: CREATE_ADVS_FINALLY,
    });
  }
};

export const updateAdvertisement = ({ id, values, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ADV_REQUEST,
    });
    const response = await axiosApp.patch(
      `/admin/advertisements/${id}`,
      values,
      { headers: { "Content-Type": "application/form-data" } },
    );
    dispatch({
      type: UPDATE_ADV_SUCCESS,
      payload: response.data.data,
    });
    callback()
    notification.success({
      message: "تم تعديل الاعلان بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message || error.response.data.body.message,
    });
  } finally {
    dispatch({
      type: UPDATE_ADV_FINALLY,
    });
  }
};

export const deleteAdvertisement = ({ id, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_ADV_REQUEST,
    });
    const response = await axiosApp.delete(
      `/admin/advertisements/${id}`,
      { headers: { "Content-Type": "application/x-www-form-urlencoded" }, },
    );
    dispatch({
      type: DELETE_ADV_SUCCESS,
      payload: response.data.data,
    });
    callback();
    notification.success({
      message: "تم حذف الاعلان بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  }  catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({ type: DELETE_ADV_FINALLY });
  }
};


export const deleteAdvertisementAll = ({ selectedRows, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_ADV_REQUEST,
    });
    const response = await axiosApp.delete(
      `/admin/advertisements/${selectedRows.toString()}`,
      { headers: { "Content-Type": "application/x-www-form-urlencoded" } },
    );
    dispatch({
      type: DELETE_ADV_SUCCESS,
      payload: response.data.data,
    });
    callback();
    notification.success({
      message: "تم حذف الاعلان بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  }catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: DELETE_ADV_FINALLY,
    });
  }
};
