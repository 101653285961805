import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTerms } from '../../../store/Website/actions/constants';
import Convention from '../../../components/Website/Convention/convention';

const TermsAndConditions = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { dataTerms, loading } = useSelector((state) => state.terms);

    useEffect(() => {
        dispatch(getTerms());
    }, [dispatch]);

    const terms = dataTerms?.data || {};

    return (<Convention item={terms} intro={'terms_intro'} loading={loading}></Convention>);
};

export default TermsAndConditions;
