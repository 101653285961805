import {
  CREATE_COMPANY_FINALLY,
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  GET_COMPANY_FINALLY,
  GET_COMPANY_REQUEST,
  GET_COMPANY_SUCCESS,
  GET_COMPANYS_FINALLY,
  GET_COMPANYS_REQUEST,
  GET_COMPANYS_SUCCESS,
  UPDATE_COMPANY_FINALLY,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_SUCCESS,
  DELETE_COMPANY_FINALLY,
  DELETE_COMPANY_REQUEST,
  DELETE_COMPANY_SUCCESS,
} from "../../actions/Companies/types";

export const company = function (state = {}, action) {
  switch (action.type) {
    case GET_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCompany: action.payload,
      };
    case GET_COMPANY_FINALLY:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const companyCreate = function (state = {}, action) {
  switch (action.type) {
    case CREATE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_COMPANY_FINALLY:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const companies = function (state = {}, action) {
  switch (action.type) {
    case GET_COMPANYS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_COMPANYS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataCompanies: action.payload,
      };
    case GET_COMPANYS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const companyDelete = function (state = {}, action) {
  switch (action.type) {
    case DELETE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_COMPANY_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const companyUpdate = function (state = {}, action) {
  switch (action.type) {
    case UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_COMPANY_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
