import { GET_POLICY_ERROR, GET_POLICY_REQUEST, GET_POLICY_SUCCESS, GET_TERMS_ERROR, GET_TERMS_REQUEST, GET_TERMS_SUCCESS } from "../../actions/constants/types";

export const terms = function (state = {}, action) {
  switch (action.type) {
    case GET_TERMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataTerms: action.payload,
      };
    case GET_TERMS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const policy = function (state = {}, action) {
  switch (action.type) {
    case GET_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        dataPolicy: action.payload,
      };
    case GET_POLICY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
