import React from 'react'

const CallingIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      fill="none"
      {...props}
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M17.34 10.179v-.727a4.993 4.993 0 0 0-4.921-4.921h-.728m9.18 6.354v-.989c0-.843 0-1.265-.032-1.62a7.988 7.988 0 0 0-7.244-7.244C13.239 1 12.816 1 11.973 1h-.989M7.768 8.213a2.755 2.755 0 0 0 .577 3.048l2.394 2.394c.8.8 2.01 1.03 3.048.577a2.755 2.755 0 0 1 3.049.577l1.456 1.457c.07.07.106.105.134.136a1.997 1.997 0 0 1 0 2.703 4.773 4.773 0 0 1-.134.137l-.885.885a2.981 2.981 0 0 1-2.755.802A17.887 17.887 0 0 1 1.072 7.348a2.981 2.981 0 0 1 .801-2.755l.885-.885c.07-.07.106-.106.137-.134a1.997 1.997 0 0 1 2.703 0l.137.134L7.19 5.164c.8.801 1.03 2.01.577 3.049Z"
      />
    </svg>  )
}

export default CallingIcon