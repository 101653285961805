import {
  GET_NOTIFICATION_LOGS_FINALLY,
  GET_NOTIFICATION_LOGS_REQUEST,
  GET_NOTIFICATION_LOGS_SUCCESS,
} from "../../actions/notification_logs/types";

export const notification_logs = function (state = {}, action) {
  switch (action.type) {
    case GET_NOTIFICATION_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATION_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataNotificationLogs: action.payload,
      };
    case GET_NOTIFICATION_LOGS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
