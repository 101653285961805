// LECTURES
export const GET_LECTURES_REQUEST = 'GET_LECTURES_REQUEST';
export const GET_LECTURES_SUCCESS = 'GET_LECTURES_SUCCESS'
export const GET_LECTURES_FINALLY = 'GET_LECTURES_FINALLY'

export const GET_ADVISERS_FOR_LECTURE_REQUEST = 'GET_ADVISERS_FOR_LECTURE_REQUEST';
export const GET_ADVISERS_FOR_LECTURE_SUCCESS = 'GET_ADVISERS_FOR_LECTURE_SUCCESS'
export const GET_ADVISERS_FOR_LECTURE_FINALLY = 'GET_ADVISERS_FOR_LECTURE_FINALLY'

export const GET_ONE_LECTURE_REQUEST = 'GET_ONE_LECTURE_REQUEST';
export const GET_ONE_LECTURE_SUCCESS = 'GET_ONE_LECTURE_SUCCESS'
export const GET_ONE_LECTURE_FINALLY = 'GET_ONE_LECTURE_FINALLY'

export const UPDATE_LECTURE_REQUEST = 'UPDATE_LECTURE_REQUEST';
export const UPDATE_LECTURE_SUCCESS = 'UPDATE_LECTURE_SUCCESS'
export const UPDATE_LECTURE_FINALLY = 'UPDATE_LECTURE_FINALLY'

export const UPDATE_LECTURE_ORDER_REQUEST = 'UPDATE_LECTURE_ORDER_REQUEST';
export const UPDATE_LECTURE_ORDER_SUCCESS = 'UPDATE_LECTURE_ORDER_SUCCESS'
export const UPDATE_LECTURE_ORDER_FINALLY = 'UPDATE_LECTURE_ORDER_FINALLY'

export const CREATE_LECTURES_REQUEST = 'CREATE_LECTURES_REQUEST';
export const CREATE_LECTURES_SUCCESS = 'CREATE_LECTURES_SUCCESS'
export const CREATE_LECTURES_FINALLY = 'CREATE_LECTURES_FINALLY'

export const DELETE_LECTURE_REQUEST = 'DELETE_LECTURES_REQUEST';
export const DELETE_LECTURE_SUCCESS = 'DELETE_LECTURES_SUCCESS'
export const DELETE_LECTURE_FINALLY = 'DELETE_LECTURE_FINALLY'
