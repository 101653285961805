import {
  GET_REQUESTS_FINALLY,
  GET_REQUESTS_REQUEST,
  GET_REQUESTS_SUCCESS,
  UPDATE_REQUESTS_STATUS_FINALLY,
  UPDATE_REQUESTS_STATUS_REQUEST,
  UPDATE_REQUESTS_STATUS_SUCCESS,
} from "../../actions/JoinCompanyRequests/types";

export const requests = function (state = {}, action) {
  switch (action.type) {
    case GET_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataRequests: action.payload,
      };
    case GET_REQUESTS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateRequestStatus = function (state = {}, action) {
  switch (action.type) {
    case UPDATE_REQUESTS_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_REQUESTS_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataUpdateStatus: action.payload,
      };
    case UPDATE_REQUESTS_STATUS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
