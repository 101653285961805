
// SOCIALMEDIA
export const GET_SOCIALMEDIA_REQUEST = 'GET_SOCIALMEDIA_REQUEST';
export const GET_SOCIALMEDIA_SUCCESS = 'GET_SOCIALMEDIA_SUCCESS'
export const GET_SOCIALMEDIA_FINALLY = 'GET_SOCIALMEDIA_FINALLY'


// SOCIALMEDIA
export const DELETE_SOCIALMEDIA_REQUEST = 'DELETE_SOCIALMEDIA_REQUEST';
export const DELETE_SOCIALMEDIA_SUCCESS = 'DELETE_SOCIALMEDIA_SUCCESS'
export const DELETE_SOCIALMEDIA_FINALLY = 'DELETE_SOCIALMEDIA_FINALLY'



// SOCIALMEDIA
export const POST_SOCIALMEDIA_REQUEST = 'POST_SOCIALMEDIA_REQUEST';
export const POST_SOCIALMEDIA_SUCCESS = 'POST_SOCIALMEDIA_SUCCESS'
export const POST_SOCIALMEDIA_FINALLY = 'POST_SOCIALMEDIA_FINALLY'


// SOCIALMEDIA
export const GET_ONE_SOCIALMEDIA_REQUEST = 'GET_ONE_SOCIALMEDIA_REQUEST';
export const GET_ONE_SOCIALMEDIA_SUCCESS = 'GET_ONE_SOCIALMEDIA_SUCCESS'
export const GET_ONE_SOCIALMEDIA_FINALLY = 'GET_ONE_SOCIALMEDIA_FINALLY'


// SOCIALMEDIA
export const ACTICATE_SOCIALMEDIA_REQUEST = 'ACTICATE_SOCIALMEDIA_REQUEST';
export const ACTICATE_SOCIALMEDIA_SUCCESS = 'ACTICATE_SOCIALMEDIA_SUCCESS'
export const ACTICATE_SOCIALMEDIA_FINALLY = 'ACTICATE_SOCIALMEDIA_FINALLY'


// SOCIALMEDIA
export const DEACTIVATE_SOCIALMEDIA_REQUEST = 'DEACTIVATE_SOCIALMEDIA_REQUEST';
export const DEACTIVATE_SOCIALMEDIA_SUCCESS = 'DEACTIVATE_SOCIALMEDIA_SUCCESS'
export const DEACTIVATE_SOCIALMEDIA_FINALLY = 'DEACTIVATE_SOCIALMEDIA_FINALLY'
