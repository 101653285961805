import { Modal } from "antd";
import axiosApp from "../../../../api/index.js";
import {
  GET_ERROR_LOGS_FINALLY,
  GET_ERROR_LOGS_REQUEST,
  GET_ERROR_LOGS_SUCCESS,
} from "./types.js";

export const getErrorLogs = ({params}) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ERROR_LOGS_REQUEST,
    });
    const response = await axiosApp.get(`/admin/error-logs` , {params} );
    dispatch({
      type: GET_ERROR_LOGS_SUCCESS,
      payload: response.data,
    });
  }  catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_ERROR_LOGS_FINALLY,
    });
  }
};
