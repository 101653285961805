import React from 'react'

const ValuesVector2 = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={265}
      height={250}
      fill="none"
      {...props}
    >
      <path
        fill="#FFC47F"
        fillOpacity={0.25}
        fillRule="evenodd"
        d="M129.731 1.191c-27.066.383-55.367-.719-77.929 14.228C28.735 30.7 14.685 56.18 6.736 82.673c-7.813 26.038-10.455 54.901 1.018 79.547 10.885 23.383 37.29 33.007 58.329 47.942 20.747 14.728 38.483 34.865 63.648 38.65 27.408 4.123 55.632-2.702 79.278-17.152 24.997-15.275 51.279-36.293 55.578-65.258 4.204-28.326-24.836-49.421-34.56-76.358-9.236-25.584-.384-58.808-20.749-76.85C188.708-5.03 157.217.803 129.731 1.19Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default ValuesVector2