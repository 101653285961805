import { Modal, notification } from "antd";
import axiosApp from "../../../../api/index.js";
import {
  GET_REQUESTS_REQUEST,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_FINALLY,
  UPDATE_REQUESTS_STATUS_REQUEST,
  UPDATE_REQUESTS_STATUS_SUCCESS,
  UPDATE_REQUESTS_STATUS_FINALLY,
} from "./types.js";

export const getRequests = ({ params }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REQUESTS_REQUEST,
    });
    const response = await axiosApp.get(`/admin/companies/requests/list` , { params });
    dispatch({
      type: GET_REQUESTS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_REQUESTS_FINALLY,
    });
  }
};

export const updateRequestStatus = ({ values, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_REQUESTS_STATUS_REQUEST,
    });
    const response = await axiosApp.patch(
      `/admin/companies/requests/status`,
      values,
      { headers: { "Content-Type": "application/json" }, },
    );
    dispatch({
      type: UPDATE_REQUESTS_STATUS_SUCCESS,
      payload: response.data.data,
    });
    callback();
    notification.success({
      message: "تم تعديل المستخدم بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: UPDATE_REQUESTS_STATUS_FINALLY,
    });
  }
};
