import { Avatar, Modal, Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import EmptyData from "../../../../components/Admin/EmptyData";
import {
  deleteCompany,
  deleteCompanyAll,
  getCompanies,
} from "../../../../store/Admin/actions/Companies";
import {
  FaPlus,
  FaRegEdit,
  FaRegTrashAlt,
} from "react-icons/fa";
import useAppParams from "../../../../hooks/useAppParams";
import CouponsFilter from "../Filter";

const CompaniesList = () => {
  const dispatch = useDispatch();

  const { UrlParams, handleSearch } = useAppParams({});
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { dataCompanies, loading } = useSelector((state) => state.companies);
 
  useEffect(() => {
    if (UrlParams) {
      dispatch(
        getCompanies({
          params: { ...UrlParams },
        })
      );
    }
  }, [dispatch, UrlParams]);

  useEffect(() => {
    setData(
      dataCompanies?.companies?.map((row, index) => ({
        key: row?.id,
        id: row?.id,
        name: row?.name,
        logo: row?.logo,
      }))
    );
  }, [dataCompanies]);

  const ChangePaginationCompanies = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
    // setPageAdv(number);data
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const columns = [
    {
      title: "الاسم",
      dataIndex: ["name", "logo"],
      render: (index, row) => (
        <div key={index}>
          <Avatar
            size={40}
            src={row?.logo}
          />{" "}
          {row?.name}
        </div>
      ),
    },
    {
      title: "الكوبونات",
      dataIndex: [],
      render: (index, row) => (
        <Link
          type="primary"
          // htmlType="button"
          to={`/admin/company-coupons/${row?.id}`}
        >
          الي قائمة الكوبونات
        </Link>
      ),
    },
    {
      title: "الاجراءات",
      key: "action",
      render: (value) => (
        <Space size="middle">
          <button
            className="text-danger bg-transparent pointer"
            onClick={() => {
              confirm({ id: value.id, type: "single", action: "delete" });
            }}
          >
            <FaRegTrashAlt />
          </button>
          <Link className="text-success" to={`${value?.id}/edit`}>
            <FaRegEdit />
          </Link>
        </Space>
      ),
    },
  ];

  const confirm = ({ id, type, action }) => {
    if (action === "delete") {
      if (type === "single") {
        Modal.confirm({
          title: "هل تريد حذف الشركة",
          okText: "تأكيد",
          cancelText: "الغاء",
          onOk: () => 
          new Promise((resolve) => {
            dispatch(
              deleteCompany({
                id,
                callback: () => {
                  dispatch(
                    getCompanies({
                      params: { ...UrlParams },
                    })
                  );
                  resolve();
                },
              })
            );
          }),
        });
      } else if (type === "multible") {
        if (selectedRows?.length === 0 || selectedRows?.length === undefined) {
          Modal.confirm({
            title: "الرجاء تحديد شركة",
            okText: "موافق",
            cancelText: "الغاء",
          });
        } else {
          Modal.confirm({
            title: "هل تريد حذف الشركة",
            okText: "تأكيد",
            cancelText: "الغاء",
            onOk: () => {
              dispatch(
                deleteCompanyAll({
                  selectedRows,
                  callback: () => {
                    setSelectedRows([]);
                    dispatch(
                      getCompanies({
                        params: { ...UrlParams },
                      })
                    );
                  },
                })
              );
            },
          });
        }
      }
    }
  };

  return (
    <>

      <CouponsFilter noneField={false} />
    
      <div className="mb-15 group-btn">
        <Link className="btn btn-add" to="create">
          <span className="icon">
            <FaPlus />
          </span>{" "}
          اضافة
        </Link>
        <button
          className="btn btn-delete"
          onClick={() => {
            confirm({ type: "multible", action: "delete" });
          }}
        >
          {" "}
          <span className="icon">
            <FaRegTrashAlt />
          </span>{" "}
          حذف
        </button>
      </div>
      <Table
        locale={{ emptyText: <EmptyData /> }}
        size="small"
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          current: Number(UrlParams?.page ? UrlParams?.page : 1),
          total: dataCompanies?.total || 0,
          onChange: (page) => {
            ChangePaginationCompanies(page);
          },
        }}
      />
    </>
  );
};

export default CompaniesList;
