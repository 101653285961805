

import axiosApp from "../../../../api/index.js";
import { GET_POLICY_ERROR, GET_POLICY_REQUEST, GET_POLICY_SUCCESS, GET_TERMS_ERROR, GET_TERMS_REQUEST, GET_TERMS_SUCCESS } from "./types.js";

export const getTerms = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_TERMS_REQUEST,
    });
    const response = await axiosApp.get(`/constants/terms`);
    dispatch({
      type: GET_TERMS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_TERMS_ERROR,
      payload: error.response.data.message,
    });
  }
};

export const getPolicy = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_POLICY_REQUEST,
    });
    const response = await axiosApp.get(`/constants/policy`);
    dispatch({
      type: GET_POLICY_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: GET_POLICY_ERROR,
      payload: error.response.data.message,
    });
  }
};
