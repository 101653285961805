
// MESSAGE_LIST
export const GET_MESSAGE_LIST_REQUEST = 'GET_MESSAGE_LIST_REQUEST';
export const GET_MESSAGE_LIST_SUCCESS = 'GET_MESSAGE_LIST_SUCCESS'
export const GET_MESSAGE_LIST_FINALLY = 'GET_MESSAGE_LIST_FINALLY'


// CONVERSATION_MESSAGE
export const GET_CONVERSATION_MESSAGE_REQUEST = 'GET_CONVERSATION_MESSAGE_REQUEST';
export const GET_CONVERSATION_MESSAGE_SUCCESS = 'GET_CONVERSATION_MESSAGE_SUCCESS'
export const GET_CONVERSATION_MESSAGE_FINALLY = 'GET_CONVERSATION_MESSAGE_FINALLY'