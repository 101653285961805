import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from "antd";

import { FaSpinner,FaRegImage } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createAdvertisement,
  getOneAdvertisement,
  updateAdvertisement,
  getSessionsList,
} from "../../../../store/Admin/actions/advertisements/index";
import Loading from "../../../../components/Admin/Loading/Loading";

const { TextArea } = Input;

const { Option } = Select;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const AdvertisementsForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const [imageUrl, setImageUrl] = useState(undefined);
  const [loadingImage, setLoadingImage] = useState(false);
  const { dataAdvertisement, loading: loadingAdvertisement } = useSelector((state) => state.advertisement);
  const { loading: loadingUpdate } = useSelector((state) => state.advertisementUpdate);
  const { loading: loadingCreate } = useSelector((state) => state.advertisementCreate);
  const { dataSessions, loading } = useSelector((state) => state.sessionsList);

  useEffect(() => {
      dispatch(getSessionsList());
      if (id) {
        setIsUpdate(true);
        dispatch(getOneAdvertisement(id));
      }
  }, [dispatch]);

  const onFinish = (values) => {
    let image = values?.image?.file?.status ? undefined : values?.image?.file;

    values = { ...values, image };

    const formData = new FormData();
    Object.keys(values).forEach((element) =>
      formData.append(element, values[element])
    );
    if (id) {
      dispatch(
        updateAdvertisement({
          id: id,
          values: formData,
          callback: () => {
            form.resetFields();
            navigate(`/admin/advertisements/${id}/edit`);
          },
        })
      );
    } else {
      dispatch(
        createAdvertisement({
          values: formData,
          callback: () => {
            form.resetFields();
            navigate(`/admin/advertisements`);
          },
        })
      );
    }
  };

  const uploadButton = (
    <div>
      {loadingImage ? <FaSpinner /> : <FaRegImage />}
      <div
        style={{
          marginTop: 8,
        }}
      ></div>
    </div>
  );

  const handleChange = ({ file }) => {
    setLoadingImage(true)
    if (file && file?.originFileObj) {
      form.setFieldsValue({
        image: file.originFileObj,
      });
    }

    getBase64(file.originFileObj, (url) => {
      setLoadingImage(false)
      setImageUrl(url);
    });
  };

  useEffect(() => {
    if (isUpdate) {
      const { session, image, ...otherData } = dataAdvertisement;
      form.setFieldsValue({ image: [] });
      form.setFieldsValue({ ...otherData });

      const getSession = dataSessions?.data?.find(
        (item) => item?.id === session?.id
      ); 

      form.setFieldsValue({ ...otherData, session: getSession });
    }
  }, [form, dataAdvertisement]);

  return (
    <Card>
      {loadingAdvertisement ? (
        <Loading />
      ) : (
        <Form
          size="large"
          form={form}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          autoComplete="off"
          // scrollToFirstError={{
          //   behavior: "smooth",
          //   block: "center",
          // }}
        >
          <Row gutter={20} justify="center">
            <Col span={24} md={10}>
              <Form.Item name="image" className="text-center">
                <Upload
                  maxCount={1}
                  accept="image/png, image/gif, image/jpeg"
                  beforeUpload={false}
                  name="image"
                  listType="picture-circle"
                  className="avatar-uploader"
                  onChange={handleChange}
                  showUploadList={false}
                >
                  {imageUrl ? (
                    <img src={imageUrl} alt="image" style={{ width: "100%" }} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
              <Form.Item
                name="session_id"
                rules={[
                  {
                    required: true,
                    message: "الحقل مطلوب",
                  },
                ]}
              >
                <Select showSearch filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
                  placeholder="الجلسات"
                  loading={loading}
                >
                  {dataSessions?.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.adviser_name} | {item.type} | {item.date?.split('T')[0]} | {item.start} | {item.end}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="title"
                rules={[
                  { required: false },
                ]}
              >
                <Input placeholder="العنوان" />
              </Form.Item>
              <Form.Item
                name="description"
                rules={[
                  { required: false },
                ]}
              >
                <TextArea rows={5} placeholder="الوصف" />
              </Form.Item>
              <Form.Item className="text-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingCreate || loadingUpdate}
                >
                   {id ? 'حفظ التعديلات' : 'إضافة '}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default AdvertisementsForm;
