import {  Avatar, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EmptyData from "../../../../components/Admin/EmptyData";
import user from "../../../../assets/images/user.png";
import useAppParams from "../../../../hooks/useAppParams";
import ErrorLogsFilter from "../Filter";
import { getNotificationLogs } from "../../../../store/Admin/actions/notification_logs";
const NotificationLogsList = () => {
  const dispatch = useDispatch();

  const { UrlParams, handleSearch } = useAppParams({});
  const [data, setData] = useState([]);
  // const [searchText, setSearchText] = useState("");
  const { dataNotificationLogs, loading } = useSelector(
    (state) => state.notification_logs
    );
  const [selectedRows, setSelectedRows] = useState([]);

  // useEffect(() => {
  //   handleSearch({
  //     fields: {
  //       page: 1,
  //     },
  //   });
  //  }, [])
  useEffect(() => {
    if (UrlParams) {
      dispatch(
        getNotificationLogs({
          params: { ...UrlParams },
        })
      );
    }
  }, [dispatch, UrlParams]);

  useEffect(() => {
    setData(
      dataNotificationLogs?.data?.map((row) => ({
        key: row.id,
        id: row.id,
        full_name_ar: row?.full_name_ar,
        avatar: row?.avatar,
        status: row?.status,
        action: row?.action,
        source: row?.source,
        created_at: row?.created_at,
      }))
    );
  }, [dataNotificationLogs]);

  const ChangePagination = (number) => {
    handleSearch({
      fields: {
        page: number,
      },
    });
  };

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };
  const columns = [
    {
      title: "إسم المستخدم",
      dataIndex: ["full_name_ar" , "avatar"],
      render: (index, row) => (
        <div key={index}>
          <Avatar
            size={40}
            src={row?.avatar ? row?.avatar : user}
          />{" "}
          {row?.full_name_ar}
        </div>
      ),
    },
    {
      title: "الحدث",
      dataIndex: "action",
    },
    {
      title: "الحالة",
      dataIndex: "status",
    },
    {
      title: "المصدر",
      dataIndex: "source",
    },
    {
      title: "التاريخ",
      dataIndex: "created_at",
    },
  ];
  let locale = {
    emptyText: <EmptyData />,
  };

  return (
    <>
     <ErrorLogsFilter />
      {/* <div className="mb-15 group-btn">
        <button
          className="btn btn-blocked"
          onClick={() => {
            confirm({ type: "multible" });
          }}
        >
          <span className="icon">
            <FaBan />
          </span>
          تعطيل
        </button>
      </div> */}
      <Table
        locale={locale}
        size="small"
        rowSelection={{
          ...rowSelection,
        }}
        columns={columns}
        loading={loading}
        dataSource={data}
        pagination={{
          defaultCurrent:1,
          current: Number(UrlParams?.page ? UrlParams?.page : 1),
          pageSize: 10,
          total: dataNotificationLogs?.pagination?.total,
          onChange: (page) => {
            ChangePagination(page);
          },
        }}
      />
    </>
  );
};

export default NotificationLogsList;
