
// USERSACTIVITY
export const GET_USERSACTIVITY_REQUEST = 'GET_USERSACTIVITY_REQUEST';
export const GET_USERSACTIVITY_SUCCESS = 'GET_USERSACTIVITY_SUCCESS'
export const GET_USERSACTIVITY_FINALLY = 'GET_USERSACTIVITY_FINALLY'

// USERSACTIVITY
export const GET_USERACTIVITY_REQUEST = 'GET_USERACTIVITY_REQUEST';
export const GET_USERACTIVITY_SUCCESS = 'GET_USERACTIVITY_SUCCESS'
export const GET_USERACTIVITY_FINALLY = 'GET_USERACTIVITY_FINALLY'
