import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Loading from "../../../../components/Admin/Loading/Loading";
import { Card, Col, Form, Input, Row } from "antd";
import { getReport } from "../../../../store/Admin/actions/sessions";

const ReportDetails = (params) => {
  const { id } = useParams();
  const report_id = params.id || id;
  const dispatch = useDispatch();

  const { dataReport, loading: loadingReport } = useSelector((state) => state.report);

  useEffect(() => {
    if (report_id) {
      dispatch(getReport(report_id));
    }
    
  }, [dispatch]);

  const diffInMilliseconds = new Date().getTime() - new Date(dataReport?.dob).getTime();
  const millisecondsPerYear = (365.25 * 24 * 60 * 60 * 1000);
  const age = !dataReport?.dob ? '' : Math.floor(diffInMilliseconds / millisecondsPerYear);

  return (
    <Card className="users-details">
      {loadingReport ? (
        <Loading />
      ) : (
        <Form
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Row gutter={20}>
            <Col span={24} md={24}>
              <strong className="text-center">تفاصيل التقرير</strong>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="اسم المستشار">
                <Input value={dataReport?.adviser_name} disabled />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="مجال العمل">
                <Input value={dataReport?.field_name} disabled />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="الإسم المستفيد باللغة العربية ">
                <Input value={dataReport?.full_name_ar} disabled />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="الإسم المستفيد باللغة الإنجليزية ">
                <Input value={dataReport?.full_name} disabled />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="الدولة">
                <Input value={dataReport?.country_name} disabled />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="الجنس">
                <Input value={dataReport?.gender} disabled />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="العمر">
                <Input value={age} disabled />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item label="عنوان الجلسة">
                <Input value={dataReport?.sessiontopic} disabled />
              </Form.Item>
            </Col>
            <Form.Item
              name="beneficiarystatus"
              label="حالة المستفيد"
            >
              <Input value={dataReport?.beneficiarystatus} disabled />
            </Form.Item>
          </Row>
          <Row gutter={20}>
            <Col span={24} md={12}>
              <Form.Item label="ملاحظات عن حالة المستفيد">
                <Input.TextArea rows={4} value={dataReport?.beneficiarynotes} disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default ReportDetails;
