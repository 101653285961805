import React from 'react'

const MessageIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={22}
      fill="none"
      {...props}
    >
      <path
        stroke="#fff"
        strokeWidth={1.5}
        d="M1.676 3.942C1 5.373 1 7.416 1 11c0 4.166 0 6.25 1.061 7.71.343.472.757.886 1.23 1.229C4.75 21 6.833 21 11 21h2.222c4.167 0 6.25 0 7.71-1.061a5.555 5.555 0 0 0 1.23-1.23c1.06-1.46 1.06-3.543 1.06-7.709 0-3.61 0-5.656-.69-7.088m-20.856.03c.11-.232.237-.448.385-.652a5.555 5.555 0 0 1 1.23-1.229C4.75 1 6.833 1 11 1h2.222c4.167 0 6.25 0 7.71 1.061.472.343.887.757 1.23 1.23.141.194.264.4.37.621m-20.856.03.435.391 2.143 2.144c3.704 3.703 5.556 5.555 7.857 5.555s4.153-1.852 7.857-5.555l2.143-2.144.421-.42"
      />
    </svg>  )
}

export default MessageIcon