import { Modal, notification } from "antd";
import axiosApp from "../../../../api/index.js";
import {
  CREATE_COMPANYCOUPON_FINALLY,
  CREATE_COMPANYCOUPON_REQUEST,
  CREATE_COMPANYCOUPON_SUCCESS,
  GET_COMPANYCOUPON_FINALLY,
  GET_COMPANYCOUPON_REQUEST,
  GET_COMPANYCOUPON_SUCCESS,
  GET_COMPANYCOUPONS_REQUEST,
  GET_COMPANYCOUPONS_SUCCESS,
  GET_COMPANYCOUPONS_FINALLY,
  UPDATE_COMPANYCOUPON_REQUEST,
  UPDATE_COMPANYCOUPON_SUCCESS,
  UPDATE_COMPANYCOUPON_FINALLY,
  DELETE_COMPANYCOUPON_REQUEST,
  DELETE_COMPANYCOUPON_SUCCESS,
  DELETE_COMPANYCOUPON_FINALLY,
  GET_COMPANYCOUPONS_REQUEST_SELECTION,
  GET_COMPANYCOUPONS_SUCCESS_SELECTION,
  GET_COMPANYCOUPONS_FINALLY_SELECTION,
} from "./types.js";

export const companyCoupon = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPANYCOUPON_REQUEST,
    });
    const response = await axiosApp.get(`/admin/company-coupons/${id}`);
    dispatch({
      type: GET_COMPANYCOUPON_SUCCESS,
      payload: response.data.data,
    });
  }  catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_COMPANYCOUPON_FINALLY,
    });
  }
};

export const createCompanyCoupon = (values) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_COMPANYCOUPON_REQUEST,
    });
    const response = await axiosApp.post(`/admin/company-coupons`, values, { headers: { "Content-Type": "application/json" }});
    dispatch({
      type: CREATE_COMPANYCOUPON_SUCCESS,
      payload: response.data.data,
    });
    notification.success({
      message: 'تمت اضافة الكوبون بنجاح',
      duration: 2,
    })
  }  catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error?.response?.data?.message || "حدث خطأ ما!",
    });
  } finally {
    dispatch({
      type: CREATE_COMPANYCOUPON_FINALLY,
    });
  }
};

export const getCompanyCoupons = ({ params }) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COMPANYCOUPONS_REQUEST,
    });
    const response = await axiosApp.get(`/admin/company-coupons` , { params });
    dispatch({
      type: GET_COMPANYCOUPONS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_COMPANYCOUPONS_FINALLY,
    });
  }
};

export const updateCompanyCoupon = ({ id, values, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_COMPANYCOUPON_REQUEST,
    });
    const response = await axiosApp.patch(
      `/admin/company-coupons/${id}`,
      values,
      { headers: { "Content-Type": "application/json" } },
    );
    dispatch({
      type: UPDATE_COMPANYCOUPON_SUCCESS,
      payload: response.data.data,
    });
    callback()
    notification.success({
      message: "تم تعديل الكوبون بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error?.response?.data?.message || error?.response?.data?.body?.message || "حدث خطأ ما!",
    });
  } finally {
    dispatch({
      type: UPDATE_COMPANYCOUPON_FINALLY,
    });
  }
};

export const deleteCompanyCoupon = ({ id, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COMPANYCOUPON_REQUEST,
    });
    const response = await axiosApp.delete(
      `/admin/company-coupons/${id}`,
      { headers: { "Content-Type": "application/json" }, },
    );
    dispatch({
      type: DELETE_COMPANYCOUPON_SUCCESS,
      payload: response.data.data,
    });
    callback();
    notification.success({
      message: "تم حذف الكوبون بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  }  catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error?.response?.data?.message || "حدث خطأ ما!",
    });
  } finally {
    dispatch({ type: DELETE_COMPANYCOUPON_FINALLY });
  }
};


export const deleteCompanyCouponAll = ({ selectedRows, callback }) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COMPANYCOUPON_REQUEST,
    });
    const response = await axiosApp.delete(
      `/admin/company-coupons/${selectedRows.toString()}`,
      { headers: { "Content-Type": "application/json" } },
    );
    dispatch({
      type: DELETE_COMPANYCOUPON_SUCCESS,
      payload: response.data.data,
    });
    callback();
    notification.success({
      message: "تم حذف الكوبون بنجاح",
      duration: 2,
      placement:"topLeft"
    });
  }catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: DELETE_COMPANYCOUPON_FINALLY,
    });
  }
};

export const getCompanyCouponsForSelection = (company_id) => async (dispatch) => {
  console.log({company_id})
  try {
    dispatch({
      type: GET_COMPANYCOUPONS_REQUEST_SELECTION,
    });
    const response = await axiosApp.get(`/admin/company-coupons/list/${company_id}`);
    dispatch({
      type: GET_COMPANYCOUPONS_SUCCESS_SELECTION,
      payload: response.data.data,
    });
  } catch (error) {
    Modal.error({
      title: "حدث خطأ ما!",
      content: error.response.data.message,
    });
  } finally {
    dispatch({
      type: GET_COMPANYCOUPONS_FINALLY_SELECTION,
    });
  }
};

