import { Card, Select, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getSourceChart } from "../../../../store/Admin/actions/dashboard";
import { useDispatch, useSelector } from "react-redux";
const Option = Select.Option;

const SourceChart = () => {
  const dispatch = useDispatch();
  const [sourceChart, setSourceChart] = useState([]);
  const { dataSourceChart, loading: loadingSourceChart } = useSelector(
    (state) => state.sourceChart
  );
  const [durationValue, setDurationValue] = useState([]);
  const [typeValue, setTypeValue] = useState([]);
  useEffect(() => {
    dispatch(
      getSourceChart({
        params: {
          type: typeValue,
          interval: durationValue
        },
      })
    );
  }, [dispatch, typeValue, durationValue]);

  useEffect(() => {
    setSourceChart(
      dataSourceChart?.data?.map((row) => ({
        date: row.date,
        count: row.count,
      }))
    );
  }, [dataSourceChart]);

  var series2 = [
    {
      name: "التفاعل",
      data: sourceChart?.map((item) => {
        return item?.count;
      }),
    },
  ];
  var options2 = {
    chart: {
      height: 280,
      type: "area",
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#64549C",
        shadeTo: "light",
        shadeIntensity: 0.65,
      },
    },
    xaxis: {
      categories: sourceChart?.map((item) => {
        return item?.date;
      }),
      labels: {
        offsetX: -15,
        offsetY: 50,
      },
    },
  };
  const handleChangeDuration = (values) => {
    setDurationValue(values);
  };
  const handleChangeType = (values) => {
    setTypeValue(values);
  };
  return (
    <Card className="mb-10">
      <div className="d-flex align-items-center justify-content-between mb-30">
        <h5>إحصائية التفاعل على النظام </h5>
        <Select
          defaultValue="4"
          onChange={handleChangeDuration}
          style={{ width: 120 }}
        >
          <Option value="4">يومي</Option>
          <Option value="3">اسبوعي</Option>
          <Option value="2">شهري</Option>
          <Option value="1">سنوي</Option>
        </Select>
        <Select
          defaultValue="الكل"
          onChange={handleChangeType}
          style={{ width: 120 }}
        >
          <Option value="all">الكل</Option>
          <Option value="mobile">التطبيق</Option>
          <Option value="web">الويب</Option>
        </Select>
      </div>
      {loadingSourceChart ? (
        <Skeleton />
      ) : (
        <ReactApexChart
          options={options2}
          series={series2}
          height="400"
          type="area"
        />
      )}
    </Card>
  );
};

export default SourceChart;
