import { Modal, notification } from "antd";
import axiosApp from "../../../../api/index.js";
import {
  JOIN_SESSION_ERROR,
  JOIN_SESSION_REQUEST,
  JOIN_SESSION_SUCCESS,
  LEAVE_SESSION_ERROR,
  LEAVE_SESSION_REQUEST,
  LEAVE_SESSION_SUCCESS,
  FINISH_SESSION_ERROR,
  FINISH_SESSION_REQUEST,
  FINISH_SESSION_SUCCESS,
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
} from "./types.js";

export const joinSession = ({ id }) => async (dispatch) => {
  try {
    dispatch({ type: JOIN_SESSION_REQUEST });
    const response = await axiosApp.patch(
      `/sessions/join`,
      { session_id: id },
      { headers: { "Content-Type": "application/json" } },
    );
    dispatch({ type: JOIN_SESSION_SUCCESS, payload: response.data.data });
    notification.success({
      message: 'تم الانضمام بنجاح',
      duration: 2,
    })
  } catch (error) {
    dispatch({ type: JOIN_SESSION_ERROR, payload: "حدث خطأ ما" });
    Modal.error({
      title: "حدث خطأ ما!",
      content: "حدث خطأ ما!",
    });
  }
};

export const leaveSession = ({ id }) => async (dispatch) => {
  try {
    dispatch({ type: LEAVE_SESSION_REQUEST });
    const response = await axiosApp.patch(
      `/sessions/leave`,
      { session_id: id },
      { headers: { "Content-Type": "application/json" } },
    );
    dispatch({ type: LEAVE_SESSION_SUCCESS, payload: response.data.data });
    notification.success({
      message: 'تم الخروج بنجاح',
      duration: 2,
    })
  } catch (error) {
    dispatch({ type: LEAVE_SESSION_ERROR, payload: "حدث خطأ ما" });
    Modal.error({
      title: "حدث خطأ ما!",
      content: "حدث خطأ ما!",
    });
  }
};

export const finishSession = ({ id }) => async (dispatch) => {
  try {
    dispatch({ type: FINISH_SESSION_REQUEST });
    const response = await axiosApp.post(
      `/sessions/finish`,
      { id },
      { headers: { "Content-Type": "application/json" } },
    );
    dispatch({ type: FINISH_SESSION_SUCCESS, payload: response.data.data });
    notification.success({
      message: 'تم الانهاء بنجاح',
      duration: 2,
    })
  } catch (error) {
    dispatch({ type: FINISH_SESSION_ERROR, payload: "حدث خطأ ما" });
    Modal.error({
      title: "حدث خطأ ما!",
      content: "حدث خطأ ما!",
    });
  }
};

export const getSessionDetails = ({ id }) => async (dispatch) => {
  try {
    dispatch({ type: GET_SESSION_REQUEST });
    const response = await axiosApp.get(
      `/sessions/${id}`,
      { headers: { "Content-Type": "application/json" } },
    );
    dispatch({ type: GET_SESSION_SUCCESS, payload: response.data.data });
  } catch (error) {
    dispatch({ type: GET_SESSION_ERROR, payload: "حدث خطأ ما" });
    Modal.error({
      title: "حدث خطأ ما!",
      content: "حدث خطأ ما!",
    });
  }
};
