
// GET BLOG
export const GET_FAQ_REQUEST = 'GET_FAQ_REQUEST';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS'
export const GET_FAQ_ERROR = 'GET_FAQ_ERROR'


// CREATE BLOG
export const CREATE_FAQ_REQUEST = 'CREATE_FAQ_REQUEST';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS'
export const CREATE_FAQ_ERROR = 'CREATE_FAQ_ERROR'


// SINGLE BLOG
export const SINGLE_FAQ_REQUEST = 'SINGLE_FAQ_REQUEST';
export const SINGLE_FAQ_SUCCESS = 'SINGLE_FAQ_SUCCESS'
export const SINGLE_FAQ_ERROR = 'SINGLE_FAQ_ERROR'

// EDIT BLOG
export const EDIT_FAQ_REQUEST = 'EDIT_FAQ_REQUEST';
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS'
export const EDIT_FAQ_ERROR = 'EDIT_FAQ_ERROR'


// DELETE BLOG
export const DELETE_FAQ_REQUEST = 'DELETE_FAQ_REQUEST';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS'
export const DELETE_FAQ_ERROR = 'DELETE_FAQ_ERROR'
