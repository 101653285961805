import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Upload,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  createCompany,
  getCompany,
  updateCompany,
} from "../../../../store/Admin/actions/Companies";
import Loading from "../../../../components/Admin/Loading/Loading";
import { FaRegImage, FaSpinner } from "react-icons/fa";
import TextEditor from "../../../../components/shared/text-editor.component";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const CompanyCreate = () => {
  const { id } = useParams();
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const { dataCompany, loading: loadingCoupon } = useSelector((state) => state.company);
  const { loading: loadingUpdate } = useSelector((state) => state.companyUpdate);
  const { loading: loadingCreate } = useSelector((state) => state.companyCreate);

  const [imageUrl, setImageUrl] = useState(undefined);
  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    isMounted.current = false; // Set to false when component unmounts
    if (id) {
      setIsUpdate(true);
      dispatch(getCompany(id));
    }  
  }, [dispatch]);

  const onFinish = (values) => {
    let logo = values?.logo?.status ? undefined : values?.logo;
    values = { ...values, logo };

    const formData = new FormData();
    Object.keys(values).forEach((element) => {
      if (!values[element]) {
        delete values[element];
      } else {
        formData.append(element, values[element]);
      }
    });

    if (id) {
      dispatch(
        updateCompany({
          id: id,
          values: formData,
          callback: () => {
            dispatch(getCompany(id));
            navigate(`/admin/companies/${id}/edit`);
          },
        })
      );
    } else {
      dispatch(
        createCompany({
          values: values,
          callback: () => {
            form.resetFields();
            navigate(`/admin/companies`);
          },
        })
      );
    }
  };

  const uploadButton = (
    <div>
      {loadingImage ? <FaSpinner /> : <FaRegImage />}
      <div
        style={{
          marginTop: 8,
        }}
      ></div>
    </div>
  );

  const handleChange = ({ file }) => {
    setLoadingImage(true)
    if (file && file?.originFileObj) {
      form.setFieldsValue({
        logo: file.originFileObj,
      });
    }
    getBase64(file.originFileObj, (url) => {
      setLoadingImage(false)
      setImageUrl(url);
    });
  };
  
  useEffect(() => {
    if (isUpdate) {
      const { logo, ...otherData } = dataCompany;
      form.setFieldsValue({ logo: [] });
      form.setFieldsValue({ ...otherData });
      setImageUrl(dataCompany?.logo)
    }
  }, [form, dataCompany]);

  return (
    <Card>
      {loadingCoupon ? (
        <Loading />
      ) : (
        <Form
          size="large"
          form={form}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={onFinish}
          autoComplete="off"
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
          }}
        >
          <Row gutter={20} justify="center">
            <Col span={24} md={24}>
              <Form.Item name="logo" className="text-center">
                <Upload
                  accept="image/png, image/gif, image/jpeg"
                  beforeUpload={false}
                  name="logo"
                  listType="picture-circle"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img src={imageUrl} alt="logo" style={{ width: "100%" }} />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24} md={10}>
              <Form.Item
                name="name_ar"
                rules={[
                  { required: false },
                ]}
                label="اسم الشركة باللغة العربية"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="name"
                rules={[
                  { required: false },
                ]}
                label="اسم الشركة باللغة الانجليزية"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="about"
                rules={[
                  { required: true },
                ]}
                label="نبذة عن الشركة باللغة الانجليزية"
              >
                <TextEditor />
              </Form.Item>
              <Form.Item
                name="about_ar"
                rules={[
                  { required: true },
                ]}
                label="نبذة عن الشركة باللغة العربية"
              >
                <TextEditor />
              </Form.Item>
              <Form.Item className="text-center">
                <Button
                  style={{ margin: '0 25px' }}
                  type="primary"
                  htmlType="submit"
                  loading={loadingCreate || loadingUpdate}
                >
                   {id ? 'حفظ التعديلات' : 'إضافة '}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default CompanyCreate;
