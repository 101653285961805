import {
  JOIN_SESSION_ERROR,
  JOIN_SESSION_REQUEST,
  JOIN_SESSION_SUCCESS,
  LEAVE_SESSION_ERROR,
  LEAVE_SESSION_REQUEST,
  LEAVE_SESSION_SUCCESS,
  FINISH_SESSION_REQUEST,
  FINISH_SESSION_SUCCESS,
  FINISH_SESSION_ERROR,
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
} from "../../actions/session-meeting/types";

export const joinSession = function (state = {}, action) {
  switch (action.type) {
    case JOIN_SESSION_REQUEST:
      return { ...state, loading: true };
    case JOIN_SESSION_SUCCESS:
      return { ...state, loading: false, joinSessionData: action.payload }; 
    case JOIN_SESSION_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export const leaveSession = function (state = {}, action) {
  switch (action.type) {
    case LEAVE_SESSION_REQUEST:
      return { ...state, loading: true };
    case LEAVE_SESSION_SUCCESS:
      return { ...state, loading: false, leaveSessionData: action.payload }; 
    case LEAVE_SESSION_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export const finishSession = function (state = {}, action) {
  switch (action.type) {
    case FINISH_SESSION_REQUEST:
      return { ...state, loading: true };
    case FINISH_SESSION_SUCCESS:
      return { ...state, loading: false, finishSessionData: action.payload }; 
    case FINISH_SESSION_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export const getSession = function (state = {}, action) {
  switch (action.type) {
    case GET_SESSION_REQUEST:
      return { ...state, loading: true };
    case GET_SESSION_SUCCESS:
      return { ...state, loading: false, getSessionData: action.payload }; 
    case GET_SESSION_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
