import {
  GET_ERROR_LOGS_FINALLY,
  GET_ERROR_LOGS_REQUEST,
  GET_ERROR_LOGS_SUCCESS,
} from "../../actions/error_logs/types";

export const error_logs = function (state = {}, action) {
  switch (action.type) {
    case GET_ERROR_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ERROR_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataErrorLogs: action.payload,
      };
    case GET_ERROR_LOGS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
