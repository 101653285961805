// COMPANY
export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS'
export const GET_COMPANY_FINALLY = 'GET_COMPANY_FINALLY'

export const CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const CREATE_COMPANY_FINALLY = 'CREATE_COMPANY_FINALLY'

export const GET_COMPANYS_REQUEST = 'GET_COMPANYS_REQUEST';
export const GET_COMPANYS_SUCCESS = 'GET_COMPANYS_SUCCESS'
export const GET_COMPANYS_FINALLY = 'GET_COMPANYS_FINALLY'

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const UPDATE_COMPANY_FINALLY = 'UPDATE_COMPANY_FINALLY'

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS'
export const DELETE_COMPANY_FINALLY = 'DELETE_COMPANY_FINALLY'
