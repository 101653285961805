
// SESSIONPRICE
export const GET_SESSIONPRICE_REQUEST = 'GET_SESSIONPRICE_REQUEST';
export const GET_SESSIONPRICE_SUCCESS = 'GET_SESSIONPRICE_SUCCESS'
export const GET_SESSIONPRICE_FINALLY = 'GET_SESSIONPRICE_FINALLY'

// SESSIONPRICEPOST
export const GET_SESSIONPRICEPOST_REQUEST = 'GET_SESSIONPRICEPOST_REQUEST';
export const GET_SESSIONPRICEPOST_SUCCESS = 'GET_SESSIONPRICEPOST_SUCCESS'
export const GET_SESSIONPRICEPOST_FINALLY = 'GET_SESSIONPRICEPOST_FINALLY'
