// JOIN_SESSION
export const JOIN_SESSION_REQUEST = 'JOIN_SESSION_REQUEST';
export const JOIN_SESSION_SUCCESS = 'JOIN_SESSION_SUCCESS';
export const JOIN_SESSION_ERROR = 'JOIN_SESSION_ERROR';

// LEAVE_SESSION
export const LEAVE_SESSION_REQUEST = 'LEAVE_SESSION_REQUEST';
export const LEAVE_SESSION_SUCCESS = 'LEAVE_SESSION_SUCCESS';
export const LEAVE_SESSION_ERROR = 'LEAVE_SESSION_ERROR';

// FINISH_SESSION
export const FINISH_SESSION_REQUEST = 'FINISH_SESSION_REQUEST';
export const FINISH_SESSION_SUCCESS = 'FINISH_SESSION_SUCCESS';
export const FINISH_SESSION_ERROR = 'FINISH_SESSION_ERROR';

// GET_SESSION
export const GET_SESSION_REQUEST = 'GET_SESSION_REQUEST';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_ERROR = 'GET_SESSION_ERROR';
