import React, { useEffect, useRef } from 'react';
import {
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
} from "agora-rtc-react";

const Videos = (props) => {
  const ref = useRef();

  const { AppID, channelName, token, user } = props;
  const { isLoading: isLoadingMic, localMicrophoneTrack } = useLocalMicrophoneTrack();
  const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack();
  const remoteUsers = useRemoteUsers();
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);

  usePublish([localMicrophoneTrack, localCameraTrack]);
  useJoin({
    appid: AppID,
    channel: channelName,
    token: token,
  });

  useEffect(() => {
    audioTracks.map((track) => track.play());
    const deviceLoading = isLoadingMic || isLoadingCam;
    if (deviceLoading) return <div>Loading devices...</div>;
    user.videoTrack.play(ref.current);
  }, []);

  return (
    <div>
      <div
        ref={ref}
        style={{
          width: '450px',
          height: '300px',
          margin: '100px',
        }}
      ></div>
    </div>
  );
};

export default Videos;
