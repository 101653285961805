import {
  CREATE_ADVS_FINALLY,
  CREATE_ADVS_REQUEST,
  CREATE_ADVS_SUCCESS,
  DELETE_ADV_FINALLY,
  DELETE_ADV_REQUEST,
  DELETE_ADV_SUCCESS,
  GET_ONE_ADV_FINALLY,
  GET_ONE_ADV_REQUEST,
  GET_ONE_ADV_SUCCESS,
  GET_ADVS_FINALLY,
  GET_ADVS_REQUEST,
  GET_ADVS_SUCCESS,
  UPDATE_ADV_FINALLY,
  UPDATE_ADV_REQUEST,
  UPDATE_ADV_SUCCESS,
  GET_SESSIONS_FOR_ADV_FINALLY,
  GET_SESSIONS_FOR_ADV_REQUEST,
  GET_SESSIONS_FOR_ADV_SUCCESS,
} from "../../actions/advertisements/types";

export const sessionsList = function (state = {}, action) {
  switch (action.type) {
    case GET_SESSIONS_FOR_ADV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_SESSIONS_FOR_ADV_SUCCESS:
      return {
        ...state,
        loading: false,
        dataSessions: action.payload,
      };
    case GET_SESSIONS_FOR_ADV_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const advertisements = function (state = {}, action) {
  switch (action.type) {
    case GET_ADVS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ADVS_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAdvertisements: action.payload,
      };
    case GET_ADVS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const advertisement = function (state = {}, action) {
  switch (action.type) {
    case GET_ONE_ADV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ONE_ADV_SUCCESS:
      return {
        ...state,
        loading: false,
        dataAdvertisement: action.payload,
      };
    case GET_ONE_ADV_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const advertisementDelete = function (state = {}, action) {
  switch (action.type) {
    case DELETE_ADV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ADV_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ADV_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const advertisementCreate = function (state = {}, action) {
  switch (action.type) {
    case CREATE_ADVS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ADVS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_ADVS_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const advertisementUpdate = function (state = {}, action) {
  switch (action.type) {
    case UPDATE_ADV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ADV_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_ADV_FINALLY:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
